/* eslint-disable @typescript-eslint/no-redeclare */
/**
 *  エンティティタイプ
 * 　（関連先タイプに使用するenumはapp.tsに定義）
 *
 *  テーブル名へ参照する値を定義しているため、キャメルケースに従う必要はない
 */
export const EntityType = {
    Customer: 'customers',
    ChatMessage: 'chat_messages',
    Opportunity: 'opportunities',
    OpportunityActivity: 'opportunity_activities',
    Project: 'projects',
    Event: 'events',
    Task: 'tasks',
    TaskActivity: 'task_activities',
    TaskComment: 'task_comments',
    File: 'files',
    User: 'users',
    Department: 'departments',
    DirectChannel: 'direct_channels',
    ProjectMemberPendings: 'project_member_pendings',
    TenantMemberPendings: 'tenant_member_pendings',
    Tenant: 'tenants',
    TaskHistories: 'task_histories',
    FileHistories: 'file_histories',
    Folders: 'folders',
    Calls: 'Calls', // DynamoDBのためPascaleCase
};
/**
 *  アテンションタイプ
 */
export const AttentionType = {
    Mention: 'mention',
    Reply: 'reply',
    Reaction: 'reaction',
};
/**
 * 通知(自動投稿)メッセージタイプ
 */
export const NotificationMessageType = {
    TaskAdded: 'taskAdded',
    TaskUpdated: 'taskUpdated',
    TaskDeleted: 'taskDeleted',
    TaskCommentUpdated: 'taskCommentUpdated',
    EventAdded: 'eventAdded',
    EventUpdated: 'eventUpdated',
    EventDeleted: 'eventDeleted',
    EventJoined: 'eventJoined',
    FileAdded: 'fileAdded',
    FileUpdated: 'fileUpdated',
    ProjectMemberRequested: 'projectMemberRequested',
    ProjectMemberJoined: 'projectMemberJoined',
    ProjectMemberLeft: 'projectMemberLeft',
    CallEnded: 'callEnded',
    CallCancelled: 'callCancelled',
    CallFailed: 'callFailed',
};
/**
 * メッセージタイプ
 */
export const MessageType = Object.assign({ MessageNormal: 'messageNormal', MassageSurvey: 'messageSurvey' }, NotificationMessageType);
/**
 * 指定したメッセージタイプが自動投稿メッセージかどうかを判定する
 * @param messageType
 * @returns
 */
export const isNotificationMessage = (messageType) => {
    return Object.values(NotificationMessageType).includes(messageType);
};
/**
 *  非同期通信用メッセージタイプ - 電話制御系
 */
export const PubSubCallEventType = {
    /**
     * 着信者が電話に出た
     */
    CallAnswered: 'callAnswered',
    /**
     * 着信者が着信拒否した
     */
    CallDeclined: 'callDeclined',
    /**
     * 通話が終了
     */
    CallEnded: 'callEnded',
    /**
     * 着信者が着信拒否または応答する前に、発信者側で発信が取り消された
     */
    CallCancel: 'callCancel',
    /**
     * 発信に失敗した（ユーザーデバイステーブルに該当デバイスが存在するが着信者側の応答がなかった場合が該当する）
     */
    CallFailed: 'callFailed',
    /**
     * ビデオ通話を開始
     */
    CallBeginVideo: 'callBeginVideo',
};
/**
 *  非同期通信用メッセージタイプ
 */
export const PubSubEventType = Object.assign({ 
    // チャットメッセージ
    ChatMessageAdded: 'chatMessageAdded', ChatMessageUpdated: 'chatMessageUpdated', ChatMessageDeleted: 'chatMessageDeleted', 
    // リアクション
    ChatMessageReactionAdded: 'chatMessageReactionAdded', ChatMessageReactionDeleted: 'chatMessageReactionDeleted', 
    // フィードアイテム
    FeedItemAdded: 'feedItemAdded', FeedItemUpdated: 'feedItemUpdated', FeedItemDeleted: 'feedItemDeleted', 
    // アテンションアイテム
    AttentionItemAdded: 'attentionItemAdded', AttentionItemDeleted: 'attentionItemDeleted', 
    // ブックマーク
    BookmarkAdded: 'bookmarkAdded', BookmarkDeleted: 'bookmarkDeleted', 
    // DM
    DirectChannelAdded: 'directChannelAdded', 
    // 未読件数
    UnreadUpdated: 'unreadUpdated', UnreadChatRoomAllRead: 'unreadChatRoomAllRead', UnreadAllChatRead: 'unreadAllChatRead', 
    // ユーザー在席状態
    UserPresenceUpdated: 'userPresenceUpdated' }, PubSubCallEventType);
/**
 * 制御系メッセージタイプ
 */
export const IncomingControlMessageType = {
    Success: 'success',
    Failure: 'failure',
    SubscribeReconnected: 'subscribeReconnected',
};
/**
 * WebSocket受信メッセージタイプ
 */
export const WebSocketIncomingMessageType = Object.assign(Object.assign({}, PubSubEventType), IncomingControlMessageType);
/**
 * WebSocket送信メッセージタイプ
 */
export const WebSocketOutgoingMessageType = {
    Subscribe: 'subscribe',
    Unsubscribe: 'unsubscribe',
};
/**
 * PubSub購読先タイプ
 */
export const PubSubEventTargetType = {
    User: 'user',
    ChatRoom: 'chatRoom',
    Call: 'call',
};
/**
 * PubSub購読先データタイプ
 */
export const PubSubEventDataType = {
    Data: 'data',
    Meta: 'meta',
    // TODO: CREW-17773 一時的対応
    // https://break-tmc.atlassian.net/browse/CREW-17773
    Presence: 'presence',
};
/**
 * 未読情報タイプ
 */
export const UnreadInfoTarget = {
    ChatRoom: 'chatRoom',
    ChatThread: 'chatThread',
};
/**
 * 宛先タイプ
 */
export const DestinationType = {
    Email: `email`,
    LoginId: `loginId`,
    UserId: `userId`,
};
/**
 * 保留状態
 */
export const PendingState = {
    Inviting: `inviting`,
    WaitingApproval: `waitingApproval`,
};
/**
 * 役割タイプ
 */
export const RoleType = {
    System: `system`,
    Project: `project`,
};
/**
 * ユーザータイプ
 */
export const UserType = {
    Internal: `internal`,
    External: `external`,
};
/**
 *  出欠タイプ
 */
export const AttendanceType = {
    Attendance: 'attendance',
    Absence: 'absence',
};
// action type in project setting member grid
export const ProjectSettingMemberActionType = {
    Cancel: 'cancel',
    Reject: 'reject',
    Delete: 'delete',
};
/**
 *  Signup type
 */
export const SignupType = {
    ProjectMemberPendings: 'projectMemberPendings',
    TenantMemberPendings: 'tenantMemberPendings',
};
/**
 * Member State
 */
export const TenantMemberState = {
    Enabled: `enabled`,
    Disabled: `disabled`,
    Inviting: `inviting`,
    WaitingApproval: `waitingApproval`,
};
/**
 * Task dependency
 */
export const TaskDependencyType = {
    // Finish to Start。先行が完了してから後続を開始する
    FinishToStart: 'fs',
    // Start to Start。先行が開始してから後続が開始する
    StartToStart: 'ss',
    // Finish to Finish。先行が完了してから後続が完了する
    FinishToFinish: 'ff',
    // Start to Finish。先行が開始してから後続が完了する
    StartToFinish: 'sf',
};
/**
 * Task Dependencies Insertion Type
 */
export const TaskDependenciesInsertionType = {
    PredecessorTask: 'predecessorTask',
    SuccessorTask: 'successorTask',
};
/**
 * Project Type
 */
export const ProjectType = {
    Project: 'project',
    DirectChannel: 'directChannel',
    InstantChannel: 'instantChannel',
};
/**
 * WBS updatable column
 *
 * 列名へ参照する値を定義しているため、キャメルケースに従う必要はない
 */
export const WbsUpdatableColumn = {
    Subject: 'subject',
    WbsNumber: 'wbs_number',
    AssignToUserId: 'assign_to_user_id',
    StartDate: 'start_date',
    DueDate: 'due_date',
    EstimatedWorkTimes: 'estimated_work_times',
    ParentTaskId: 'parent_task_id',
};
/**
 * Event Type
 */
export const EventType = {
    Personal: 'personal',
    Project: 'project',
};
/**
 * Group schedule filter
 */
export const GroupScheduleFilterType = {
    GroupScheduleProjectFilter: 'project',
    GroupScheduleCustomFilter: 'custom',
};
/**
 * Event scope
 */
export const EventScope = {
    Public: 'public',
    Private: 'private',
};
/**
 * KeywordFilterCondition
 */
export const KeywordFilterCondition = {
    And: 'and',
    Or: 'or',
};
export const VerifyBulkInsertUsersResultCode = {
    Invalid: 'invalid',
    Available: 'available',
    Warning: 'warning',
    Registered: 'registered',
};
/**
 * MeetingAttendeeJoinState
 */
export const MeetingAttendeeJoinState = {
    Waiting: 'waiting',
    Joining: 'joining',
    Exited: 'exited',
};
/**
 *  Type tenant setting members filter
 */
export const TenantMemberFilter = {
    AllTenantMembers: 'allTenantMembers',
    EnabledTenantMembers: 'enabledTenantMembers',
    DisabledTenantMembers: 'disabledTenantMembers',
    InvitingTenantMembers: 'invitingTenantMembers',
};
/**
 * Kanban updatable column
 *
 * 列名へ参照する値を定義しているため、キャメルケースに従う必要はない
 */
export const KanbanUpdatableColumn = {
    TaskStateId: 'task_state_id',
    AssignToUserId: 'assign_to_user_id',
    TaskKindId: 'task_kind_id',
    TaskCategoryId: 'task_category_id',
    TaskPriority: 'task_priority',
};
/**
 *  Type of kanban bucket
 */
export const KanbanBucketType = {
    TaskState: 'taskState',
    AssignToUser: 'assignToUser',
    TaskKind: 'taskKind',
    TaskCategory: 'taskCategory',
};
/**
 * PresenceStateType
 */
export const PresenceStateType = {
    Present: 'present',
    Busy: 'busy',
    Away: 'away',
    Absent: 'absent',
    Unknown: 'unknown',
};
/**
 * ChangeEventScopeType
 */
export const ChangeEventScopeType = {
    // この予定
    ThisEvent: 'thisEvent',
    // これ以降のすべての予定
    FutureEvents: 'futureEvents',
    // すべての予定
    AllEvents: 'allEvents',
};
/**
 * RecurrenceRule
 */
export const RecurrenceRule = {
    Daily: 'daily',
    Weekdays: 'weekdays',
    Weekly: 'weekly',
    MonthlyDate: 'monthlyDate',
    MonthlyDayOfWeek: 'monthlyDayOfWeek',
    Yearly: 'yearly',
};
/**
 * RecurrenceRuleWithNone
 */
export const RecurrenceRuleWithNone = Object.assign(Object.assign({}, RecurrenceRule), { None: 'none' });
/**
 *  action type when end meeting
 */
export const EndMeetingActionType = {
    CloseMeeting: 'closeMeeting',
    LeaveMeeting: 'leaveMeeting',
};
/**
 * Device Type
 */
export const DeviceType = {
    Ios: 'ios',
    Android: 'android',
    Web: 'web',
};
/**
 * Call Status
 */
export const CallStatus = {
    // 発信開始
    CallStarted: 'callStarted',
    // 発信中
    Calling: 'calling',
    // 通話中
    InCall: 'inCall',
    // 通話終了
    CallEnded: 'callEnded',
    // 発信キャンセル
    CallCancelled: 'callCancelled',
    // 発信失敗
    CallFailed: 'callFailed',
};
/**
 * Webミーティングのモード
 */
export const WebMeetingMode = {
    /**
     * 通話
     */
    Call: 'call',
    /**
     * ビデオ通話
     */
    CallWithVideo: 'callWithVideo',
    /**
     * 通常のWebミーティング
     */
    Normal: 'normal',
};
