import { FC, memo, RefObject, useCallback, useState } from 'react'
import classNames from 'classnames'
import { isNotificationMessage, MessageType } from '@crew/enums/domain'
import { useChatThreadListMessageListItem } from './useChatThreadListMessageListItem'
import { AvatarPosition } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageActionMenu } from 'components/elements/crewChatMessageItem/components/crewChatMessageActionMenu/crewChatMessageActionMenu'
import { CrewEditMessageItem } from 'components/elements/crewMessageItem/components/crewEditMessageItem/crewEditMessageItem'
import { CrewNormalMessageItem } from 'components/elements/crewMessageItem/components/crewNormalMessageItem/crewNormalMessageItem'
import { CrewNotificationMessageItem } from 'components/elements/crewMessageItem/components/crewNotificationMessageItem/crewNotificationMessageItem'
import { ShowReplyButtonType, ShowReactionType } from '@crew/utils/chat'
import { useProjectPermissions, useValueChangeEffect } from '@crew/hooks'
import { useInView } from 'react-intersection-observer'
import { ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN } from 'configs/constants'
import { useHasHover } from 'hooks/useHasHover'

export type ChatThreadListMessageListItemProps = {
  id: string
  chatMessageId: string // チャットメッセージID
  replyCount: number // 返信件数。返信ボタンに表示する
  container: RefObject<HTMLDivElement>
  onMessageInView: (inView: boolean, messageId: string) => void
  onAdditionalLoading: (() => void) | undefined // 追加読み込みの関数
  onFirstUnreadMessageViewed?: (() => void) | undefined // 画面内に最終既読メッセージが表示されているときに発火する
  isFirstUnreadMessage: boolean // 未読ラインが表示されているメッセージかどうか
  setSelectedItemId?: (messageId: string) => void // 選択されたメッセージを更新するために使用する
  selectedItemId?: string // 選択されたメッセージのハイライト用に使用する
  onClickChatReadStatus?: (chatMessageId: string) => void // A function to call when viewing the chat message read status
}

/**
 * ThreadList形式のメッセージアイテム
 * messageTypeに応じて表示コンポーネントを出し分ける
 */
export const ChatThreadListMessageListItem: FC<ChatThreadListMessageListItemProps> =
  memo((props) => {
    const {
      currentChatRoom,
      message,
      isError,
      toggleEditMode,
      cancelEditMode,
      openTargetThread,
      updateChatMessage,
      isMyMessage,
      isEditMode,
    } = useChatThreadListMessageListItem(props.chatMessageId)

    // 上端、下端、メッセージ本体の要素を表示したかどうかのフラグ
    const [isShownTopDiv, setIsShowTopDiv] = useState(false)
    const [isShownBottomDiv, setIsShownBottomDiv] = useState(false)
    const [isShownMessageDiv, setIsShownMessageDiv] = useState(false)

    const hasHover = useHasHover()
    const [isHover, setIsHover] = useState<boolean>(false)

    // ------------------------------ イベントハンドラ ------------------------------
    // propsの関数は代入して使用（useEffect内で使用時、依存配列にpropsが入ってしまうため）
    const handleMessageInView = props.onMessageInView
    const handleFirstUnreadMessageViewed = props.onFirstUnreadMessageViewed

    // 返信ボタンクリックイベントハンドラ
    const handleReplyButtonClick = useCallback(() => {
      // 返信ボタンをクリックされた投稿に紐づくスレッドを表示する
      openTargetThread()

      // ハイライト表示するため、選択されたメッセージを更新する
      props.setSelectedItemId && message && props.setSelectedItemId(message?.id)
    }, [message, openTargetThread, props])

    // マウスホバー時にアクションメニューを表示する
    const handleMouseEnter = useCallback(() => {
      setIsHover(true)
    }, [setIsHover])

    // マウスが離れたらアクションメニューを非表示にする
    const handleMouseLeave = useCallback(() => {
      setIsHover(false)
    }, [setIsHover])

    // 「編集」押下時に編集モードを切り替える
    const handleEditButtonClick = useCallback(() => {
      toggleEditMode()
    }, [toggleEditMode])

    // 編集モードの登録 / キャンセル時に編集モードをoffにする
    const handleEditModeCancel = useCallback(() => {
      cancelEditMode()
    }, [cancelEditMode])

    // 添付ファイル削除後にメッセージ更新APIを実行してwebsocketによる表示の更新を行う
    const handleFileDeleted = useCallback(async () => {
      updateChatMessage()
    }, [updateChatMessage])

    // メッセージの要素が一部でも表示されたらメッセージの表示フラグを更新する
    const { ref: messageRef } = useInView({
      threshold: 0, // 一部でも表示したときに発火する
      root: props.container.current,
      onChange: (inView) => {
        setIsShownMessageDiv(inView)
        // メッセージの要素が非表示になった場合は、上端・下端の要素も非表示になったとみなす
        if (!inView) {
          setIsShowTopDiv(false)
          setIsShownBottomDiv(false)
        }
      },
    })

    // 上端の要素が表示されたら表示フラグを更新する
    const { ref: topRef } = useInView({
      root: props.container?.current,
      onChange: (inView) => {
        // 上端の要素が非表示になった場合でも、メッセージの要素が表示されている場合は表示したことがあるとみなす
        if (!inView && isShownMessageDiv) {
          return
        }

        setIsShowTopDiv(inView)
      },
    })

    // 下端の要素が表示されたら表示フラグを更新する
    const { ref: bottomRef } = useInView({
      root: props.container?.current,
      onChange: (inView) => {
        // 下端の要素が非表示になった場合でも、メッセージの要素が表示されている場合は表示したことがあるとみなす
        if (!inView && isShownMessageDiv) {
          return
        }
        setIsShownBottomDiv(inView)
      },
    })

    const isFirstUnreadMessage = props.isFirstUnreadMessage
    // 表示状態が更新されたら各イベントハンドラを実行する
    useValueChangeEffect(
      () => {
        if (isShownTopDiv && isShownBottomDiv) {
          /* メッセージ表示（上端、下端どちらも表示したことがある） */

          // 既読管理やスクロール位置の保持を行うイベントハンドラ実行
          handleMessageInView(true, props.chatMessageId)

          // 画面内に最終既読メッセージが表示された際のイベントハンドラ実行
          // （最終既読メッセージでない場合はpropsにイベントハンドラが指定されないため、何も動作しない）
          handleFirstUnreadMessageViewed?.()
        } else if (!isShownTopDiv && !isShownBottomDiv) {
          /* メッセージ非表示（上端、下端どちらも表示したことがない） */

          // 既読管理やスクロール位置の保持を行うイベントハンドラ実行
          handleMessageInView(false, props.chatMessageId)
        }
      },
      [
        handleMessageInView,
        handleFirstUnreadMessageViewed,
        isShownBottomDiv,
        isShownTopDiv,
        props.chatMessageId,
      ],
      { isShownTopDiv, isShownBottomDiv, isFirstUnreadMessage },
      false,
      (prev, next) =>
        prev.isShownTopDiv === next.isShownTopDiv &&
        prev.isShownBottomDiv === next.isShownBottomDiv &&
        prev.isFirstUnreadMessage === next.isFirstUnreadMessage
    )

    // アイテムが表示領域に近づいたら追加ロードイベントハンドラを呼ぶ
    const { ref: loadingTriggerRef } = useInView({
      rootMargin: ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN, // 表示領域に「近づいた」をトリガーにするため、領域の外側にマージンを付与する
      root: props.container.current,
      onChange: (inView) => {
        // このイベントは、アイテムと判定領域の重なり方の割合がthreshold(デフォルト: 0)を越えた場合に発火する。
        // 近づいた場合と離れた場合のどちらも発火するが、inViewの値で方向を判定することができる。
        //   近づいた場合: true 離れた場合: false
        if (inView) {
          // アイテムが近づいてきた場合、追加読込を行う
          props.onAdditionalLoading?.()
        }
      },
    })

    // ファイル関連の権限を取得
    const {
      hasPrjFileDeletePermission,
      hasPrjFileDownloadPermission,
      hasPrjFileCreatePermission,
    } = useProjectPermissions(
      currentChatRoom?.entityType,
      currentChatRoom?.entityRecordId
    )

    // 選択中メッセージが自メッセージかどうかを判定
    const isSelected =
      props.selectedItemId !== undefined && message?.id === props.selectedItemId

    // メニューを表示するかどうか
    const visibleActionMenu = hasHover
      ? isHover //ホバー有効ならホバー状態であること
      : isSelected //ホバー無効なら選択状態であること

    // メッセージの表示コンポーネントを返す
    const renderMessageItem = useCallback(() => {
      // メッセージがない場合は何も表示しない
      if (!message) return null

      // 編集モードの場合は編集用コンポーネントを表示
      if (isEditMode) {
        return (
          <CrewEditMessageItem
            message={message}
            onEditModeCancel={handleEditModeCancel}
            canUploadFile={hasPrjFileCreatePermission}
            // アバターはすべてサイズを大きくする
            isLargeAvatar={true}
            // アバターはすべて中央寄せにする
            avatarPosition={AvatarPosition.Center}
          />
        )
      }

      // messageTypeに応じて表示コンポーネントを出し分ける
      if (message.messageType === MessageType.MessageNormal) {
        // 通常投稿メッセージ
        return (
          <CrewNormalMessageItem
            message={message}
            showRelatedLink={true} // 関連先リンクを表示する
            showDeleteAttachmentButton={
              isMyMessage && hasPrjFileDeletePermission
            }
            onAttachmentFileDeleted={handleFileDeleted}
            canDownloadAttachment={hasPrjFileDownloadPermission}
            highlightKeyword=""
            onReplyClick={handleReplyButtonClick}
            // アバターはすべてサイズを大きくする
            isLargeAvatar={true}
            // アバターはすべて中央寄せにする
            avatarPosition={AvatarPosition.Center}
            showReplyButtonType={ShowReplyButtonType.ShowWithCount} // 「○件の返信」ボタンを表示する
            showReactionsAndReactionButton={ShowReactionType.ShowAndButton} // リアクションボタンを表示する
            replyCount={props.replyCount}
            onClick={undefined}
          />
        )
      } else if (isNotificationMessage(message.messageType)) {
        // 自動投稿メッセージ
        return (
          <CrewNotificationMessageItem
            message={message}
            showRelatedLink={true} // 関連先リンクを表示する
            canDownloadAttachment={hasPrjFileDownloadPermission}
            onReplyClick={handleReplyButtonClick}
            // ThreadListではアイコンを必ず表示する
            omitUserAvatar={false}
            showReplyButtonType={ShowReplyButtonType.ShowWithCount} // 「○件の返信」ボタンを表示する
            showReactionsAndReactionButton={ShowReactionType.ShowAndButton} // リアクションボタンを表示する
            truncateMessage={false} // メッセージテキストを省略表示しない
            replyCount={props.replyCount}
            onClick={undefined}
          />
        )
      } else {
        return null
      }
    }, [
      handleEditModeCancel,
      handleFileDeleted,
      handleReplyButtonClick,
      hasPrjFileCreatePermission,
      hasPrjFileDeletePermission,
      hasPrjFileDownloadPermission,
      isEditMode,
      isMyMessage,
      message,
      props.replyCount,
    ])

    const handleClick = useCallback(() => {
      // hover機能がないデバイスに限り、メッセージ自体のクリックを返信ボタンのクリックに読み換える
      if (!hasHover) {
        handleReplyButtonClick()
      }
    }, [handleReplyButtonClick, hasHover])

    // 表示に必要なデータが指定されていない場合、表示することができないので、エラーを表示する
    if (!currentChatRoom) {
      return null
    }

    // エラーが発生している場合はエラーが発生した旨を表示する
    if (isError) {
      return null
    }

    // 表示に必要なデータがない場合は何も表示しない
    // 当初「読み込み中」を表示しようとしていたが、メッセージごとにその表示が出てしまうと見栄えが悪かったので表示しないようにした
    if (!message) {
      return null
    }

    return (
      <div
        id={props.id}
        className={classNames('flex flex-col crew-border-gray relative', {
          'bg-crew-blue-1-light dark:bg-crew-blue-3-dark': isSelected, // 対象メッセージが選択されている場合ハイライトする
          'bg-crew-gray-100 dark:bg-crew-gray-800': !isSelected && isHover, // ホバー表示、ただし選択されていたらそちらを優先する
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {/* メッセージ上端の要素。既読処理に使用するのみで画面には何も表示しない */}
        <div ref={topRef} className="-z-10"></div>

        {props.onAdditionalLoading && (
          <div
            ref={loadingTriggerRef}
            className="absolute left-0 top-0 right-0 bottom-0 -z-10"
          />
        )}

        {/* メッセージ */}
        <div ref={messageRef}>
          {/* message item */}
          {renderMessageItem()}
        </div>

        {/* ホバーメニュー */}
        {/* メッセージ枠右上にホバーメニューを表示するためabsoluteを使用 */}
        {!message.deleted && (
          <div className="absolute top-0 right-0">
            <CrewChatMessageActionMenu
              visible={visibleActionMenu}
              isBookmarkedMessage={
                message.bookmarks ? message.bookmarks.length > 0 : false
              }
              disabledChatReadStatus={!isMyMessage}
              bookmarkMessages={message.bookmarks}
              chatMessageId={props.chatMessageId}
              chatMessageVersion={message.version}
              // 自分が投稿したメッセージでない場合や通常メッセージでない(現状だと自動投稿メッセージである)場合はコンテキストメニューを使用できなくする
              disabledContextMenu={
                !isMyMessage ||
                message.messageType !== MessageType.MessageNormal
              }
              onEditButtonClick={handleEditButtonClick}
              onClickChatReadStatus={props.onClickChatReadStatus}
            />
          </div>
        )}

        {/* メッセージ下端の要素。既読処理に使用するのみで画面には何も表示しない */}
        <div ref={bottomRef} className="-z-10"></div>
      </div>
    )
  })
