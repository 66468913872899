//TODO utils配下でよいか検討し、必要なら別フォルダに移動
import { omitBy, isUndefined, isEqual } from 'lodash';
// オブジェクトの値に、undefinedやnull以外があるならばtrue
export const isFilledFieldExistsInObject = (obj) => {
    // ピュアなオブジェクトでないならtrue
    if (obj.constructor.name !== 'Object') {
        return true;
    }
    //
    return Object.keys(obj).some((key) => {
        var _a;
        // ピュアなオブジェクトならば再帰
        if (((_a = obj[key]) === null || _a === void 0 ? void 0 : _a.constructor.name) === 'Object') {
            return isFilledFieldExistsInObject(obj[key]);
        }
        return obj[key] !== undefined && obj[key] !== null;
    });
};
// Invalid Date という文字列を空文字にする
export const emptifyInvalidDate = (str) => str === 'Invalid Date' ? '' : str;
/**
 * 「ulidをidに持つアイテムの配列」をidでソートするための比較関数を生成する
 * @param order ソート順
 * @returns (a:{id:string}, b:{id:string})=>number
 * @example
 * const items: {id:string}[] = [...]
 * items.sort(ulidItemComparer('asc'))
 */
export const ulidItemComparer = (order) => (a, b) => {
    const aid = a.id.toUpperCase();
    const bid = b.id.toUpperCase();
    return (aid > bid ? 1 : aid < bid ? -1 : 0) * (order === 'asc' ? 1 : -1);
};
/**
 * 任意のキーを持つアイテムの配列をソートするための比較関数を生成する。
 * @param order
 * @param key string型のキーを指定する  ※string型以外を指定した場合、ソートされない
 * @returns
 */
export const itemComparer = (order, key) => (a, b) => {
    // keyがstringではない場合はソートしない
    if (typeof a[key] !== 'string') {
        return 0;
    }
    // as stringだとコンパイルエラーになるため、as anyで回避
    const aid = a[key].toUpperCase();
    const bid = b[key].toUpperCase();
    return (aid > bid ? 1 : aid < bid ? -1 : 0) * (order === 'asc' ? 1 : -1);
};
/**
 * 配列をソートするための日付の比較関数
 * @param a
 * @param b
 * @returns
 */
export const dateComparer = (a, b, order) => {
    switch (order) {
        case 'asc':
            return a.isAfter(b) ? 1 : a.isBefore(b) ? -1 : 0;
        case 'desc':
            return a.isAfter(b) ? -1 : a.isBefore(b) ? 1 : 0;
    }
};
export const isNotNull = (v) => v !== undefined && v !== null;
export const isNumber = (value) => Number.isFinite(value);
export const isString = (value) => typeof value === 'string';
export const isDate = (date) => {
    return typeof date === 'object' && date instanceof Date;
};
// undefinedを除いたプロパティが同じかを比較する
export const isEqualParams = (a, b) => {
    const aWithoutUndefined = omitBy(a, isUndefined);
    const bWithoutUndefined = omitBy(b, isUndefined);
    return isEqual(aWithoutUndefined, bWithoutUndefined);
};
// 簡易的なユニーク文字列を生成
export const uniqueString = () => {
    var result = Math.random().toString(32).substring(2, 12);
    return result;
};
/**
 * 配列をuniq化する関数を生成する
 * @param getKey 要素のuniq判定に使うkeyを取得する関数
 * @returns
 */
export const arrayUniqGenerator = (getKey) => (input) => Object.values(input.reduce((obj, e) => {
    obj[getKey(e)] = e;
    return obj;
}, {}));
/**
 * 配列をuniq化する
 * @param input uniqを適用したい配列
 * @returns
 */
export const arrayUniq = (input) => Array.from(new Set(input));
/**
 * 配列内の値をユニーク化するためのフィルタ関数。filter()の引数に渡すと重複する値が除去される
 * @param v
 * @param i
 * @param orig
 * @returns
 */
export const dedupeFilter = (v, i, orig) => orig.indexOf(v) === i;
// 指定した文字列のハッシュ値を返す関数
export const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr;
        // eslint-disable-next-line no-bitwise
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
//check isValid email
export const isValidEmailAddress = (email) => {
    // パターンを定義
    // https://www.javadrive.jp/regex-basic/sample/index13.html
    const regex = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    return regex.test(email);
};
/**
 * Check if an email address is associated with an internal domain.
 * @param {string} email - The email address to be checked.
 * @param {string} domain - A semicolon-separated string containing internal domain patterns.
 * @returns {boolean} - True if the email address belongs to an internal domain, false otherwise.
 */
export const isInternalEmailAddress = (email, domain) => {
    // Split the domain string into individual patterns
    const patterns = domain.split(';');
    // Check if the email address ends with any of the internal domain patterns
    const isInternal = patterns.some((pattern) => {
        return email.endsWith(pattern);
    });
    return isInternal;
};
// Parse a param string to boolean
export const convertStringToBoolean = (str) => {
    return /true/.test(str.toLowerCase());
};
/**
 * valueがnever型であること、つまりこの関数は型定義上絶対に実行されないことをアサーションする。
 * valueがnever型でない場合、つまり実行される可能性がある場合は型エラーになる。
 * 万一never型なのに実行されてしまった場合はエラーをthrowする。
 * switchのdefault節やifブロックの最後で使用することで、全てのケースを網羅していることをアサーションできる。
 */
export const assertNever = (value, message) => {
    throw new Error(message);
};
/**
 * camelCaseの文字列をkebab-caseに変換する。
 * @param camelCaseString
 * @returns
 */
export const camelToKebab = (camelCaseString) => {
    return camelCaseString
        .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
        .toLowerCase();
};
// This function build and return url parameters as an object from filters.definition
export const buildObjUrlParamsFromFilterDefinition = (filter) => {
    if (!filter) {
        return {};
    }
    const params = {};
    params['filter'] = filter.id;
    const definition = JSON.parse(filter.definition);
    const filtersOjb = definition['filters'];
    // Build search condition
    for (var i in filtersOjb) {
        params[filtersOjb[i]['field']] = filtersOjb[i]['value'];
    }
    // Build sort condition
    if (definition['orders']) {
        params['sort'] = definition['orders'];
    }
    return params;
};
// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
// ファイル履歴かどうかを判定する
export const isFileHistory = (attachment) => {
    return attachment.fileId !== undefined;
};
// regex format for scheduled time
export const isRegexFormatScheduledTime = (str) => {
    // Regex time format ex: 24:00 (24 hours, 00 minutes), 30:45 (30 hours, 45 minutes)
    const regexScheduledTime = new RegExp('^([0-9]{1,2}):([0-9]{2})$');
    if (!str || (str && regexScheduledTime.test(str))) {
        return true;
    }
    return false;
};
// convert scheduled time string to minutes
export const convertScheduledTimeToMinutes = (value) => {
    if (value) {
        const [hours, minutes] = value.split(':').map(Number);
        return hours * 60 + minutes;
    }
    return undefined;
};
// convert minutes to HH:MM format
export const convertMinutesToHHMM = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60); // Round to the nearest minute
    return `${hours}:${mins.toString().padStart(2, '0')}`;
};
// 秒をmm:ss形式に変換
export const convertSecondsToMMSS = (seconds) => {
    // NOTICE: dayjsを使用してフォーマットすると、60分を超えた際に正しく表示されない(※)ため、自前でフォーマットする
    // ※ 例）95分を表示する場合、下記のように1時間35分と解釈される関係で、`mm:ss`形式に変換すると`35:00`と表示されてしまう
    //        dayjs(0).add(95, 'minute').format('mm:ss') => '35:00'
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`;
};
// keyの型を厳密にしたObject.entries
export const strictTypeObjectEntries = (obj) => {
    return Object.entries(obj);
};
// generate items for comboBox actual progress working time
export const generateItemsComboBoxActualProgressWorkingTime = () => {
    const items = [];
    for (let i = 0; i <= 100; i += 10) {
        items.push({ value: i, label: i + '%' });
    }
    return items;
};
// Remove characters that are `'\t', '\n', '\v', '\f', '\r', ' ', 0x85, 0xA0` on both sides of the string
export const trimSpace = (str) => {
    // Create a regular expression to match the characters to remove
    const regex = /^[\t\n\v\f\r \x85\xA0]+|[\t\n\v\f\r \x85\xA0]+$/g;
    // Use replaceAll to remove all found matches
    return str.replaceAll(regex, '');
};
/**
 * 同期関数をPromise化する。
 * ただしプリエンプティブにできる訳ではないので注意
 * @param fn Promise化したい関数
 * @returns
 */
export const promisify = (fn) => (...args) => new Promise((resolve, reject) => {
    setTimeout(() => {
        try {
            resolve(fn(...args));
        }
        catch (error) {
            reject(error);
        }
    }, 0);
});
